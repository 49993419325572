<template>
  <div id="join">
    <div class="header">
      <img src="@/assets/join/jionBg.png" alt="" />
    </div>
    <div class="line2">
      <div class="line2-1">
        <div class="line2-1-1">小珈享学教育行业新风向</div>
        <div class="line2-1-2">面向全国火热招商</div>
      </div>
      <div class="line2-c">
        <div class="line2-2">
          <img src="@/assets/join/形状 1125.png" alt="" v-if="!this.showme" />
          <img src="@/assets/join/形状 1125@2x.png" alt="" v-if="this.showme" />
          双师课堂
        </div>
        <div class="line2-2">
          <img src="@/assets/join/组 78.png" alt="" v-if="!this.showme" />
          <img src="@/assets/join/组 78@2x.png" alt="" v-if="this.showme" />
          课程输出
        </div>
        <div class="line2-2">
          <img src="@/assets/join/经验.png" alt="" v-if="!this.showme" />
          <img src="@/assets/join/经验@2x.png" alt="" v-if="this.showme" />
          无需教育经验
        </div>
        <div class="line2-2">
          <img src="@/assets/join/形状 1140.png" alt="" v-if="!this.showme" />
          <img src="@/assets/join/形状 1140@2x.png" alt="" v-if="this.showme" />
          轻松当校长
        </div>
      </div>
    </div>
    <div class="line3">
      <div class="line3-1">小珈享学未来课堂计划</div>
      <div class="line3-2">打破传统教学模式 用心做好产品</div>
      <div class="line3-3">
        <div class="line3-3-1">传统线下面授/在线教育</div>
        <div class="line3-3-1">
          <img src="@/assets/join/vs.png" alt="" v-if="!this.showme" />
          <img src="@/assets/join/vs@2x.png" alt="" v-if="this.showme" />
        </div>
        <div class="line3-3-1">小珈享学AI自适应教育</div>
      </div>
      <div class="line3-4">
        <div class="line3-4-2">
          <div class="line3-4-2-title">传统教育</div>
          <div class="line3-4-2-item">
            招聘难、培养难、流失率高，教学质量难保障
          </div>
          <div class="line3-4-2-item">租金高、面积大、选址受限</div>
          <div class="line3-4-2-item">
            同质化竞争激烈，续费率低，受全国性品牌压制
          </div>
          <div class="line3-4-2-item">市场人员、咨询师等人力成本高</div>
          <div class="line3-4-2-item">学生提分难保障，难以形成口碑</div>
        </div>
        <div class="line3-4-1">
          <div
            class="line3-4-1-item"
            style="height:110px; line-height:110px;color:#fff;font-size:40px"
          >
            <img src="@/assets/join/vs1.png" alt="" v-if="!this.showme" />
          </div>
          <div class="line3-4-1-item">师资</div>
          <div class="line3-4-1-item">房租</div>
          <div class="line3-4-1-item">招生</div>
          <div class="line3-4-1-item">运营</div>
          <div class="line3-4-1-item">效果</div>
        </div>
        <div class="line3-4-3">
          <div class="line3-4-3-title">小珈享学</div>
          <div class="line3-4-3-item">
            汇总100多名特级教师的教学内容、教学方法
          </div>
          <div class="line3-4-3-item">楼层面积限制少，租金大幅度降低</div>
          <div class="line3-4-3-item">
            创新招生引流神奇-智能测评系统，用更低成本的获客渠道捕获更精准的目标客户
          </div>
          <div class="line3-4-3-item">总部提供管家式全方位服务</div>
          <div class="line3-4-3-item">
            小珈享学AI一对一教育，因材施教，打造个性化学习方案，学习轻松有效果
          </div>
        </div>
      </div>
    </div>
    <div class="line4">
      <div class="line4-1">小珈享学核心优势</div>
      <div class="line4-2">人工智能创新模式，引领教育新风口</div>
      <div class="line4-3">
        <div class="line4-3-item">
          <img src="@/assets/join/advantage1.png" alt="" v-if="!this.showme" />
          <img
            src="@/assets/join/advantage1@2x.png"
            alt=""
            v-if="this.showme"
          />
          <div class="line4-3-item-title">教学资源共享</div>
          <div class="line4-3-item-content">
            海量、结构化，动态开放的题库以及多媒体资源库线上共享
          </div>
        </div>
        <div class="line4-3-item">
          <img src="@/assets/join/advantage2.png" alt="" v-if="!this.showme" />
          <img
            src="@/assets/join/advantage2@2x.png"
            alt=""
            v-if="this.showme"
          />
          <div class="line4-3-item-title">学情可视化</div>
          <div class="line4-3-item-content">
            通过图标等可视化图形，形象生动展现数据分析结果，不同维度的学情一目了然
          </div>
        </div>
        <div class="line4-3-item">
          <img src="@/assets/join/advantage3.png" alt="" v-if="!this.showme" />
          <img
            src="@/assets/join/advantage3@2x.png"
            alt=""
            v-if="this.showme"
          />
          <div class="line4-3-item-title">数据智能分析</div>
          <div class="line4-3-item-content">
            通过手写图文识别与大数据技术，精准定位薄弱点，掌握学习情况的动态变化
          </div>
        </div>
        <div class="line4-3-item">
          <img src="@/assets/join/advantage4.png" alt="" v-if="!this.showme" />
          <img
            src="@/assets/join/advantage4@2x.png"
            alt=""
            v-if="this.showme"
          />
          <div class="line4-3-item-title">云端批改</div>
          <div class="line4-3-item-content">
            作业、试卷云端批改，附带全部题型，实时生成学情报告，大幅提高工作学习效率
          </div>
        </div>
        <div class="line4-3-item">
          <img src="@/assets/join/advantage5.png" alt="" v-if="!this.showme" />
          <img
            src="@/assets/join/advantage5@2x.png"
            alt=""
            v-if="this.showme"
          />
          <div class="line4-3-item-title">个性化教学</div>
          <div class="line4-3-item-content">
            通过智能精准检测与推荐，因材施教，提供个性化自主高效学习方案
          </div>
        </div>
      </div>
    </div>
    <div class="line5">
      <div class="line5-1">
        小珈享学已签约500+家学校，为什么万千家长选择我们？
      </div>
      <div class="line5-2">针对7-18岁学生4大学习问题，小珈享学AI一次解决</div>
      <div class="line5-3">
        <div class="line5-3-item img1">
          <div class="line5-3-item-1">
            <img src="@/assets/join/组 126(4).png" alt="" v-if="!this.showme" />
            <img
              src="@/assets/join/组 126@2x(4).png"
              alt=""
              v-if="this.showme"
            />
          </div>
          <div class="line5-3-item-c">
            <!-- <img src="@/assets/join/1.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/1@2x.png" alt="" v-if="this.showme" /> -->
            <div class="num">一</div>
            <div class="line5-3-item-2">学业压力大、时间不够用？</div>
            <div class="line5-3-item-3">
              名师纳米级细化知识点，靶向考纲效果好
            </div>
          </div>
        </div>
        <div class="line5-3-item img2">
          <div class="line5-3-item-1">
            <img src="@/assets/join/组 126(5).png" alt="" v-if="!this.showme" />
            <img
              src="@/assets/join/组 126@2x(5).png"
              alt=""
              v-if="this.showme"
            />
            <!-- <img src="@/assets/join/2.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/2@2x.png" alt="" v-if="this.showme" /> -->
          </div>
          <div class="line5-3-item-c">
            <!-- <img src="@/assets/join/1.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/1@2x.png" alt="" v-if="this.showme" /> -->
            <div class="num">一</div>
            <div class="line5-3-item-2">只会死记硬背，不能融会贯通？</div>
            <div class="line5-3-item-3">
              多维度学习数据报告，真人教师在线指导
            </div>
          </div>
        </div>
      </div>
      <div class="line5-3" style="margin-top:0px">
        <div class="line5-3-item img3">
          <div class="line5-3-item-1">
            <img src="@/assets/join/组 126(6).png" alt="" v-if="!this.showme" />
            <img
              src="@/assets/join/组 126@2x(6).png"
              alt=""
              v-if="this.showme"
            />
            <!-- <img src="@/assets/join/3.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/3@2x.png" alt="" v-if="this.showme" /> -->
          </div>
          <div class="line5-3-item-c">
            <!-- <img src="@/assets/join/1.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/1@2x.png" alt="" v-if="this.showme" /> -->
            <div class="num">一</div>
            <div class="line5-3-item-2">反复刷题，缺乏有效学习规划？</div>
            <div class="line5-3-item-3">
              针对每一位学生需求，智能推荐学习路径
            </div>
          </div>
        </div>
        <div class="line5-3-item img4">
          <div class="line5-3-item-1">
            <img src="@/assets/join/组 126(7).png" alt="" v-if="!this.showme" />
            <img
              src="@/assets/join/组 126@2x(7).png"
              alt=""
              v-if="this.showme"
            />
            <!-- <img src="@/assets/join/4.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/4@2x.png" alt="" v-if="this.showme" /> -->
          </div>
          <div class="line5-3-item-c">
            <!-- <img src="@/assets/join/1.png" alt="" v-if="!this.showme" />
            <img src="@/assets/join/1@2x.png" alt="" v-if="this.showme" /> -->
            <div class="num">一</div>
            <div class="line5-3-item-2">任务多效率低，多重任务碎片切换？</div>
            <div class="line5-3-item-3">
              （测-学-练-测-辅）针对薄弱点，系统学习战胜题海
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line6">
      <div class="line6-1">一站式运营服务，助您轻松创业</div>
      <div class="line6-2">
        总部为您提供“从选址、培训、招生到运营”强大支持成为坚实后盾
      </div>
      <div class="line6-3">
        <img src="@/assets/join/serve.png" alt="" v-if="!this.showme" />
        <img src="@/assets/join/serve@2x.png" alt="" v-if="this.showme" />
        <div class="line6-3-1">
          <div class="line6-3-1-item">选址装修</div>
          <div class="line6-3-1-item">资源盘点</div>
          <div class="line6-3-1-item">团队培训</div>
          <div class="line6-3-1-item">运营管理</div>
          <div class="line6-3-1-item">教学培训</div>
          <div class="line6-3-1-item">招生引流</div>
          <div class="line6-3-1-item">系统支持</div>
          <div class="line6-3-1-item">市场支持</div>
          <div class="line6-3-1-item">品牌助力</div>
        </div>
      </div>
    </div>
    <div class="line7">
      <div class="line7-1">抢占市场先机，互利共赢</div>
      <div class="line7-2">截止2021年4月，已签约500+家校区</div>
      <div class="line7-3">
        <div @click="goPre()">
          <img
            class="pre"
            src="@/assets/join/组 90.png"
            alt=""
            v-if="!this.showme && !isPre"
          />
          <img
            class="pre"
            src="@/assets/join/组 90@2x.png"
            alt=""
            v-if="this.showme && !isPre"
          />
          <img
            class="pre-2"
            src="@/assets/join/组 90 拷贝.png"
            alt=""
            v-if="!this.showme && isPre"
          />
          <img
            class="pre-2"
            src="@/assets/join/组 90 拷贝@2x.png"
            alt=""
            v-if="this.showme && isPre"
          />
        </div>
        <div class="next" @click="goNext()">
          <img src="@/assets/join/组 90.png" alt="" v-if="!this.showme" />
          <img src="@/assets/join/组 90@2x.png" alt="" v-if="this.showme" />
        </div>
        <div class="line7-3-fu">
          <div class="line7-3-item" v-for="item in line7List" :key="item.id">
            <img style="width:285;height:176px" :src="item.src" alt="" />
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="line8" style="position:relative">
      <!-- <div class="line8-1">未来已来 邀您共赢</div> -->
      <!-- <div class="line8-2">立即咨询，获得行业资料；免费试用，智能教育系统</div> -->
      <div class="bg">
        <!-- <img src="../assets/join/jionEmail.png" alt="" /> -->
      </div>
      <div class="line8-1" style="width: 320px;">
        <p class="title">加盟只需三步</p>
        <div class="step">
          <div class="odd">
            <div>
              <img src="@/assets/join/图层 4.png" alt="" />
            </div>
            <p>留言咨询</p>
          </div>
          <div class="even"></div>
          <div class="odd">
            <div>
              <img src="@/assets/join/图层 5.png" alt="" />
            </div>
            <p>等待回访</p>
          </div>
          <div class="even"></div>
          <div class="odd">
            <div>
              <img src="@/assets/join/图层 6.png" alt="" />
            </div>
            <p>签约合作</p>
          </div>
        </div>
        <p class="foot">用科技让教育更高效，更公平！</p>
        <p class="foot">www.xjai100.com</p>
      </div>
      <div class="line8-3">
        <textarea
          @keyup="problem = problem.replace(/^\s*|\s*$/g, '')"
          name=""
          id="line8-3-textarea"
          maxlength="200"
          v-model="problem"
          cols="30"
          rows="10"
          placeholder="请输入或选择咨询内容"
        ></textarea>
        <div class="item-box">
          <div class="item-box-left">
            <div class="item-box-left-one">姓<span></span>名</div>
            <div class="item-box-left-two"></div>
          </div>
          <input
            class="input"
            @keyup="name = name.replace(/\s*/g, '')"
            type="text"
            placeholder="请输入您的姓名"
            maxlength="5"
            v-model="name"
          />
        </div>
        <br />
        <div class="item-box">
          <div class="item-box-left">
            <div class="item-box-left-one">电<span></span>话</div>
            <div class="item-box-left-two"></div>
          </div>
          <input
            class="input"
            @keyup="phone = phone.replace(/\D/g, '')"
            type="tel"
            placeholder="请输入您的手机号码"
            maxlength="11"
            v-model="phone"
          />
        </div>
        <br />
        <div class="item-box">
          <div class="item-box-left">
            <div class="item-box-left-one">验证码</div>
            <div class="item-box-left-two"></div>
          </div>
          <input
            class="input three"
            @keyup="code = code.replace(/\D/g, '')"
            type="text"
            maxlength="6"
            v-model="code"
            placeholder="请输入验证码"
          />
          <br />
        </div>
        <span class="code-btn" @click="handleIconClick"> {{ btnTxt }}</span>
        <br />
        <button class="btn" @click="submit" :plain="true">立即咨询</button>
      </div>
      <div class="line8-4">
        <div class="question-title">
          <div>
            您可以根据个人意向选择下列【快捷留言】
          </div>
          <div class="arrows"></div>
        </div>
        <div class="question-item">
          <ul @click="axiba($event)">
            <li>小珈享学这个项目很好，请尽快与我详谈！</li>
            <li>请问我所在的地区有小珈享学的加盟校了吗？</li>
            <li>对项目很感兴趣，请将小珈享学项目资料发邮件给我！</li>
            <li>做为小珈享学的加盟校能得到哪些支持？</li>
            <li>请问，现在加盟小珈享学所需要的费用有哪些？</li>
            <li>想了解小珈享学的加盟流程，请与我联系！</li>
            <li>我想加盟小珈享学，请电话联系我！</li>
            <li>现在加盟小珈享学，有什么优惠政策吗？</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="line9" v-if="isshowme">
      <div class="line9-1">
        <div class="line9-1-top">免费试用</div>
        <div class="line9-1-bottom">智能教育系统</div>
      </div>
      <input
        type="text"
        @keyup="name = name.replace(/\s*/g, '')"
        placeholder="姓名"
        maxlength="5"
        v-model="name"
      />
      <input
        type="tel"
        @keyup="phone = phone.replace(/\D/g, '')"
        placeholder="联系电话"
        maxlength="11"
        v-model="phone"
      />
      <input
        type="text"
        @keyup="code = code.replace(/\D/g, '')"
        maxlength="6"
        v-model="code"
        placeholder="验证码"
      />
      <p
        @click="handleIconClick"
        style="
          margin-left: -90px;
          margin-top: 41px;
          font-size: 13px;
          color: #ff9611;
          width: 80px;
          cursor: pointer;
          text-align: center;
          user-select: none;
          padding-right: 10px;
        "
      >
        {{ btnTxt }}
      </p>
      <textarea
        class="line9-area"
        @keyup="problem = problem.replace(/^\s*|\s*$/g, '')"
        name=""
        maxlength="200"
        v-model="problem"
        cols="30"
        rows="10"
        placeholder="简单描述您的问题"
      ></textarea>
      <div class="line9-btn" @click="submit" :plain="true">立即咨询</div>
    </div>
  </div>
</template>

<script>
import { throttle } from "../js/func.js";
import { consultQuestion, getCode } from "../server/api";
// params:cls [选择器 .class ]
// 获取元素信息
function getBoxInfo(cls) {
  return getDom(cls).getBoundingClientRect();
}

function getDom(cls) {
  return document.querySelector(cls);
}

export default {
  data() {
    return {
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      line7List: [], //line7抢占市场先机的图片集合
      name: "",
      phone: "",
      code: "",
      problem: "",
      a: 0, //滚动图累计值
      isPre: true,
      preTime: Date.now(), //节流用
      isshowme: true, //底部咨询栏显示？
      btnTxt: "获取验证码",
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 2800) {
        this.isshowme = false;
      } else {
        this.isshowme = true;
      }
    });
  },
  methods: {
    axiba(e) {
      if (e.target.nodeName === "LI") {
        this.problem = e.target.innerHTML;
      }
    },
    handleIconClick() {
      let btn = this.checkForm();
      if (btn && this.btnTxt == "获取验证码") {
        this.timeComputer();
        let param = {
          openId: "gsApp",
          phone: this.phone,
        };
        getCode(param).then((res) => {
          if (res && res.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
              offset: 100,
            });
          } else {
            this.$message({ message: res.message, type: "error", offset: 100 });
            clearInterval(this.time2);
            this.btnTxt = "获取验证码";
          }
        });
      }
    },
    isPoneAvailable(num) {
      if (!String(num).trim()) {
        console.log("okkkkkkkkkkk");
        this.$message({ message: "请输入手机号", type: "error", offset: 100 });
        return false;
      }
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(String(num))) {
        this.$message({
          message: "请输入正确手机号",
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        return true;
      }
    },
    timeComputer() {
      let time = 60;
      let that = this;
      this.btnTxt = time + "s";
      this.time2 = setInterval(function() {
        time--;
        if (time < 0) {
          clearInterval(that.time2);
          that.btnTxt = "获取验证码";
          return;
        }
        that.btnTxt = time + "s";
      }, 1000);
    },
    checkForm() {
      if (!this.isPoneAvailable(this.phone)) {
        return false;
      } else {
        return true;
      }
    },

    goPre() {
      if (Date.now() - this.preTime < 500) {
        return;
      }
      this.preTime = Date.now();

      const outBoxInfo = getBoxInfo(".line7-3"),
        inBoxInfo = getBoxInfo(".line7-3-fu");

      if (outBoxInfo.left > inBoxInfo.left) {
        this.a += 305;
        getDom(".line7-3-fu").style.transform = `translate(${this.a}px)`;
      } else {
        this.isPre = true;
      }
    },
    goNext() {
      this.isPre = false;

      if (Date.now() - this.preTime < 500) {
        return;
      }
      this.preTime = Date.now();

      const outBoxInfo = getBoxInfo(".line7-3"),
        inBoxInfo = getBoxInfo(".line7-3-fu");

      if (outBoxInfo.right + 50 < inBoxInfo.right) {
        this.a -= 305;
        getDom(".line7-3-fu").style.transform = `translate(${this.a}px)`;
      }
    },
    //获取一些信息
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      let a = currentWidth > 1920 ? "" : "";
      this.showme = currentWidth > 1920 ? 0 : 0;

      this.line7List = [
        {
          id: 0,
          title: "深圳龙岗校区",
          src: require(`../assets/join/1-0.jpg`),
        },
        {
          id: 1,
          title: "深圳光明区弘乐校区",
          src: require(`../assets/join/1-1.jpg`),
        },
        {
          id: 2,
          title: "福建泉州校区",
          src: require(`../assets/join/1-2.jpg`),
        },
        {
          id: 4,
          title: "第1001恒大校区",
          src: require(`../assets/join/1-4.jpg`),
        },
        {
          id: 3,
          title: "厦门民立校区",
          src: require(`../assets/join/1-3.jpg`),
        },
        {
          id: 5,
          title: "东莞校区",
          src: require(`../assets/join/1-5.jpg`),
        },
        // {
        //   id: 6,
        //   title: "厦门同安七彩校区",
        //   src: require(`../assets/join/1-6.jpg`),
        // },
        // {
        //   id: 7,
        //   title: "厦门学管家校区",
        //   src: require(`../assets/join/1-7${a}.png`),
        // },
        // {
        //   id: 8,
        //   title: "厦门湖里华美校区",
        //   src: require(`../assets/join/1-8${a}.png`),
        // },

        // {
        //   id: 9,
        //   title: "深圳平安里校区",
        //   src: require(`../assets/join/1-9${a}.png`),
        // },
        // {
        //   id: 10,
        //   title: "深圳童苑教育",
        //   src: require(`../assets/join/1-10${a}.png`),
        // },
        // {
        //   id: 11,
        //   title: "塘家分校校区",
        //   src: require(`../assets/join/1-11${a}.png`),
        // },
        // {
        //   id: 12,
        //   title: "武汉校区",
        //   src: require(`../assets/join/1-12${a}.png`),
        // },
      ];
    },
    checkemp: throttle(function(e) {
      console.log(e);
    }, 200),
    //提交
    submit: throttle(function() {
      if (this.name.length === 0) {
        this.$message({
          message: "名字不能为空",
          type: "error",
          offset: 400,
        });
        return;
      }
      if (this.phone.length !== 11) {
        this.$message({
          message: "号码格式不正确",
          type: "error",
          offset: 400,
        });
        return;
      }
      if (this.code.length !== 6) {
        this.$message({
          message: "请输入正确的验证码",
          type: "error",
          offset: 400,
        });
        return;
      }
      if (!this.problem.length) {
        this.$message({
          message: "问题不能为空",
          type: "error",
          offset: 400,
        });
        return;
      }

      const submitData = {
        name: this.name,
        phone: this.phone,
        code: this.code,
        remark: this.problem,
        source: "web",
      };

      // this.$http
      //   .post(
      //     `https://test.xiaoguoai.cn:8083/admin/addIntentionalCustomer`,
      //     submitData
      //   )
      consultQuestion(submitData)
        .then((res) => {
          if (res && res.code === 200) {
            this.$message({
              message: "预约成功",
              type: "success",
              offset: 400,
            });
            this.name = "";
            this.phone = "";
            this.code = "";
            this.problem = "";
            clearInterval(this.time2);
            this.btnTxt = "获取验证码";
          } else {
            this.$message({
              message: res.message,
              type: "error",
              offset: 400,
            });
            clearInterval(this.time2);
            this.btnTxt = "获取验证码";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500),
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/join.styl';
</style>
